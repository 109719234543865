import React from "react";
import { connect } from "scrivito";
import {
  ErrorBoundary,
  HBErrorBoundary,
  Layout,
  Navigation,
  ToastContainerWrapper,
} from "components";
import { Provider as NiceModalProvider } from "@ebay/nice-modal-react";
import {
  AppBaseProvider,
  ConfiguratorPopupProvider,
  CookieConsentProvider,
  HelmetWrapperProvider,
  PageLoadStateProvider,
  ProjectSummarySidebarProvider,
  UrqlProvider,
  UserContextProvider,
} from "providers";
import "react-toastify/dist/ReactToastify.css";

const App = connect(({ callback }) => (
  <div ref={callback}>
    <HBErrorBoundary>
      <ErrorBoundary>
        <CookieConsentProvider>
          <HelmetWrapperProvider>
            <PageLoadStateProvider>
              <UrqlProvider>
                <AppBaseProvider>
                  <UserContextProvider>
                    <ConfiguratorPopupProvider>
                      <NiceModalProvider>
                        <ProjectSummarySidebarProvider>
                          <div className="min-vh-100 d-flex flex-column">
                            <ToastContainerWrapper />
                            <Navigation />
                            <Layout />
                          </div>
                        </ProjectSummarySidebarProvider>
                      </NiceModalProvider>
                    </ConfiguratorPopupProvider>
                  </UserContextProvider>
                </AppBaseProvider>
              </UrqlProvider>
            </PageLoadStateProvider>
          </HelmetWrapperProvider>
        </CookieConsentProvider>
      </ErrorBoundary>
    </HBErrorBoundary>
  </div>
));

export default App;
