import "./polyfills";
import * as React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import * as Scrivito from "scrivito";
import { configure } from "config";
import App from "./App";

configure();

if (window.preloadDump) {
  Scrivito.preload(window.preloadDump).then(({ dumpLoaded }) => {
    delete window.preloadDump;
    dumpLoaded ? hydrateApp() : renderApp();
  });
} else {
  window.prerenderReady = false;
  renderApp(() =>
    Scrivito.finishLoading().then(() => {
      window.prerenderReady = true;
    }),
  );
}

function renderApp(renderCallback) {
  const container = document.getElementById("application");
  const root = createRoot(container);
  import(/* webpackChunkName: "font-awesome" */ "./assets").then(() =>
    root.render(<App callback={renderCallback} />),
  );
}

function hydrateApp() {
  const container = document.getElementById("application");

  import(/* webpackChunkName: "font-awesome" */ "./assets").then(() => {
    hydrateRoot(container, <App callback={() => Scrivito.updateContent()} />);
  });
}
